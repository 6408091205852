<div style=" display:flex;align-items: center; justify-content: center; width: 100%;">
<mat-card>
  <mat-card-header>
    <mat-card-title >
      Vor-Ort-Service Störfall melden
  </mat-card-title>
  </mat-card-header>
    <mat-card-content>
        <form [formGroup]="Form">
            <mat-form-field class="fullWidth" appearance="fill">
                <mat-label>Seriennummer</mat-label>
                <input matInput formControlName="seriennummer" type="number">
              </mat-form-field>
            <mat-form-field class="fullWidth"  appearance="fill">
              <mat-label>Kundennummer</mat-label>
              <input matInput formControlName="kundennummer">
            </mat-form-field>
            <div class="form-row">
               <mat-form-field appearance="fill">
                    <mat-label>Firmenname</mat-label>
                    <input matInput formControlName="firmenname">
               </mat-form-field>
               <mat-form-field  appearance="fill">
                    <mat-label>Antragsteller</mat-label>
                    <input matInput formControlName="antragsteller">
                </mat-form-field>
            </div>
            <div class="form-row">
            <mat-form-field  appearance="fill">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" type="email">
            </mat-form-field>
            <mat-form-field  appearance="fill">
              <mat-label>Telefonnummer</mat-label>
              <input matInput formControlName="telefonnummer">
            </mat-form-field>
            </div>
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Fehlerbeschreibung</mat-label>
              <textarea matInput formControlName="fehlerbeschreibung" placeholder="Bitte geben Sie eine präzise Fehlerbeschreibung ein."></textarea>
            </mat-form-field>
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Fehlereingrenzung</mat-label>
              <textarea matInput formControlName="fehlereingrenzung" placeholder="Welche Schritte wurden zur Eingrenzung des Fehlers unternommen?"></textarea>
            </mat-form-field>
            <p>Kontakt für eventuelle Rückfragen durch unseren Support:</p>
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput formControlName="ansprechpartnerName">
            </mat-form-field>
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Telefonnummer</mat-label>
              <input matInput formControlName="ansprechpartnerTelefonnummer" type="tel">
            </mat-form-field>
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Email</mat-label>
              <input matInput formControlName="ansprechpartnerEmail" type="email">
            </mat-form-field>
        </form>
        <p>Upload-Protokolldatei:</p>
        <h3>Upload-Protokolldatei (.csv,.txt):</h3>
        <div style="display: flex; margin-bottom: 20px;">
            <input type="file" #fileInput (change)="onFileSelected($event)" accept=".csv,.zip,.txt" style="display: none"/>
            <button mat-raised-button color="primary" (click)="fileInput.click()">Datei auswählen</button>
            <label>{{file}}</label>
        </div>
        
        <mat-card>
            <mat-card-content [formGroup]="Form">
                <mat-checkbox formControlName="agbchecked" >
                  <div style="white-space: normal;">
                    Hiermit akzeptiere ich die <a href="https://www.bluechip.de/AGB">Allgemeinen Geschäftsbedingungen</a> der
                    bluechip Computer AG.
                  </div>
                </mat-checkbox>
                <br>
                <mat-checkbox formControlName="datenschutzchecked">
                  <div style="white-space: normal;">
                    Ich habe die <a href="https://www.bluechip.de/media/pdf/7a/0b/49/Datenschutzhinweise-bluechip-Computer-AG.pdf">Hinweise zum Datenschutz</a>
                    zur Kenntnis genommen.
                  </div>
                </mat-checkbox>
              </mat-card-content>
        </mat-card>
    </mat-card-content>
    <mat-card-actions>
        
        <button mat-raised-button color="primary" type="submit" [disabled]="!Form.valid" (click)="onSubmit()">Absenden</button>
    </mat-card-actions>
</mat-card>
</div>
<div class="content">
</div>
