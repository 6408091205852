import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {BcSnackBarService, Destroyer} from 'bcSharedComponents';
import { CustomerDto, RmaService, VorortService } from 'bcWwsWebApi';
import { VorortStoerfallDto } from 'projects/bc-wws-web-api/src/lib/model/vorortStoerfallDto';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-vorortstoerfall',
  templateUrl: './vorortstoerfall.component.html',
  styleUrls: ['./vorortstoerfall.component.sass'],
})
export class VorortstoerfallComponent extends Destroyer implements OnInit {
  constructor(
    private _snackbar: BcSnackBarService,
    public _rmaService: RmaService,
    public _vorortService: VorortService,
    private route: ActivatedRoute,
    private router :Router
  ) {
    super();
    this.Form = this.createForm();
  }

  seriennummer!: string;
  Form: FormGroup;
  file: string = '';
  fileToUpload!: File;
  customer: CustomerDto = {
    kundennummer: undefined,
    firmenname: undefined,
    antragsteller: undefined,
    mail: undefined,
    telefonnummer: undefined,
  };
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.seriennummer = params['seriennummer'];
    });
  }
  private createForm(): FormGroup {
    this.getCustomer();
    return new FormGroup({
      seriennummer: new FormControl('', [Validators.required]),
      kundennummer: new FormControl('', [Validators.required]),
      firmenname: new FormControl('', [Validators.required]),
      antragsteller: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      telefonnummer: new FormControl('0815'),
      file: new FormControl(false),
      agbchecked: new FormControl(false, Validators.requiredTrue),
      datenschutzchecked: new FormControl(false, Validators.requiredTrue),
      fehlerbeschreibung: new FormControl('', Validators.required),
      fehlereingrenzung: new FormControl('', Validators.required),
      ansprechpartnerName: new FormControl('', Validators.required),
      ansprechpartnerTelefonnummer: new FormControl('', Validators.required),
      ansprechpartnerEmail: new FormControl('', [Validators.required,Validators.email]),
    });
  }
  getCustomer() {
    this._rmaService.apiRmaGetCustomerGet()
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (data: CustomerDto) => {
        this.customer = data;
        this.Form.patchValue({
          telefonnummer: this.customer.telefonnummer,
          email: this.customer.mail,
          firmenname: this.customer.firmenname,
          antragsteller: this.customer.antragsteller,
          kundennummer: this.customer.kundennummer,
          seriennummer: this.seriennummer,
        });
      }
    });
  }
  onSubmit() {
    if (!this.Form.valid) {
      this._snackbar.show('Bitte füllen Sie den Antrag vollständig aus!', 'OK');
      return;
    }

  const stoerfallDto: VorortStoerfallDto = this.Form.value as VorortStoerfallDto;
  stoerfallDto.seriennummer = this.seriennummer;
  const dtoString = JSON.stringify(stoerfallDto);
  const filesToSend: Blob[] = [];

  if (!(this.file === "Keine Datei ausgewählt" || this.file === "" || this.file === null)) {
    const blobWithFileName = new File([new Blob([this.fileToUpload], { type: this.fileToUpload.type })], this.fileToUpload.name, { type: this.fileToUpload.type });
    filesToSend.push(blobWithFileName);
  }

  const blob2: Blob = new File([new Blob([dtoString], { type: 'application/json' })], 'dto', { type: 'application/json' });
  filesToSend.push(blob2);

    this._vorortService
      .apiVorortPostVorortStoerfallPost(filesToSend)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: boolean) => {
          this._snackbar.show('Vielen Dank. Der Antrag ist bei uns eingegangen!','OK');
          this.router.navigate(['/suche']);
          console.log('Erfolgreiche Antwort:', response);
        },
        error: (error) => {
          console.error('Fehler beim API-Aufruf:', error);
        },
      });
  }

  logFormControlStatus() {
    Object.keys(this.Form.controls).forEach((field) => {
      const control = this.Form.get(field);

      if (control instanceof FormControl) {
        console.log(`${field}: ${control.valid ? 'valid' : 'not valid'}`);
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.logNestedControls(control);
      }
    });
  }

  logNestedControls(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((field) => {
      const control = group.get(field);

      if (control instanceof FormControl) {
        console.log(`${field}: ${control.valid ? 'valid' : 'not valid'}`);
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.logNestedControls(control);
      }
    });
  }
  onFileSelected(event: any): void {
    const file = event.target.files[0];
//ZIP auch
    if (file) {
      const fileType = file.type;
      if (fileType === 'text/csv' || fileType === 'text/plain'|| fileType === 'application/zip') 
      {
        this.file = file.name;
        this.fileToUpload = file;
        console.log('Gültige Datei ausgewählt');
      } else {
        this._snackbar.show("Ungültiger Dateityp. Bitte wählen Sie eine CSV- oder TXT-Datei.");
        console.log(
          'Ungültiger Dateityp. Bitte wählen Sie eine CSV- oder TXT-Datei.'
        );
      }
    } else {
      this.file = 'Keine Datei ausgewählt';
    }
  }
}
